<template>
  <div>
    <el-form ref="smsformref" :model="smsform" label-width="180px">
      <el-form-item :label="$t('cs.qddxyz')" required>
        <el-switch
          v-model="smsform.sms_isopend"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>

      <el-form-item :label="$t('cs.zhanghu')">
        <el-input v-model="smsform.sms_account"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.mima')">
        <el-input v-model="smsform.sms_password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      smsform: {
        sms_isopend: '0',
        sms_account: '',
        sms_password: ''
      }
    }
  },
  created() {
    this.getsms()
  },
  methods: {
    async getsms() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/smsconfig')
      if (data) {
        if (data.code === 200) {
          this.smsform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/smsconfig',
        this.smsform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getsms()
    }
  }
}
</script>
